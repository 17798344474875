import { environment } from "../../../../../src/environments/environment";

/**
 * 
 * @param path : / 이후의 path를 입력하세요
 * ex) 'monitoring/pv'
 * @returns 
 */
export function initPath(path: string) {
    return function (prototype: any, name: string){
        Object.defineProperty(prototype, name, {
            value: `${environment.base_url}/` + path
        })
    }

}