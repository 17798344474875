import { EnvironmentName } from "./enum/environmentname.enum";
import { commonEnv } from "./environment.common";

//common environment에서 원하는 설정만 커스텀하는 구조.
const env: Partial<typeof commonEnv> = {
    environmentName: EnvironmentName.Development,
    base_url: 'https://api.loworldsoft.com:8080',
    socket_url: 'wss://api.loworldsoft.com:8080',
    isLegacy: true,
    geminiKey : 'U2FsdGVkX18vfHxSnBwRX7tkxA3uB9A64hSmDxRfj1WXW7FOp/m9Nj9pnlddk7wyOznaPBMUMIZmaK7m8syDAw==',
    gptKey : 'U2FsdGVkX1/2OeNFIeswTnZ07tLPpIwPvWrKK5HpQiQNadbYaVTFCkjRlqeD50gNiRqOk+3k090jHT73St6oEnQGpwvhYkGDEoLj7vd2Dlo=',
    /* ex)
    */
};

export const environment = {...commonEnv, ...env,}